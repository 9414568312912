<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="background-color: #4671C4;box-shadow: 0px 2px 5px 2px #bcbcbc;">
        <div class="the-menu-logo"><img :src="systemInfo.systemLogo" alt="" :style="is_collapse?'width:64px':''"></div>
        <el-menu
                v-if="isSystem && isSystem == 'Link'"
                class="the-menu"
                default-active="1"
                :active-text-color="theme_menu.active_text"
                :background-color="theme_menu.background"
                :collapse="is_collapse"
                :text-color="theme_menu.text"
        >
            <el-menu-item v-for="item of showMenu"
                          :key="item.mid"
                          :index="item.mid"
                          @click="goto(null, item.activeRule+item.path),active(item.mid), addBreadCrumb(null, item),addNavBread(null, item)"
                          style="background-color: inherit!important;">
                <component :is="item.i" :width="15" :height="15"/>
                <span style="margin-left: 10px">{{language == "en"? item.guideEnglish : item.guideChinese}}</span>
            </el-menu-item>
        </el-menu>
        <el-menu
                v-else
                class="the-menu"
                default-active="1"
                :active-text-color="theme_menu.active_text"
                :background-color="theme_menu.background"
                :collapse="is_collapse"
                :text-color="theme_menu.text"
        >
            <el-sub-menu v-for="sub of showMenu" :key="sub.mid" :index="sub.mid">
                <template #title>
                    <component :is="sub.i" :width="15" :height="15"/>
                    <span style="margin-left: 10px">{{language == "en"? sub.guideEnglish : sub.guideChinese}}</span>
                </template>
                <el-menu-item v-for="item of sub.children"
                              :key="item.mid"
                              :index="item.mid"
                              @click="goto(sub.module, sub.activeRule+item.path),active(sub.mid+'-'+item.mid), addBreadCrumb(sub, item),addNavBread(sub, item)">
                    {{language == "en"? item.guideEnglish : item.guideChinese}}
                </el-menu-item>
            </el-sub-menu>
        </el-menu>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"; // 引入状态共享
    import {routerGo} from "@/utils/utils.js"; // 引入跨应用路由跳转
    import {apiPost} from "@/utils/axios-utils";
    import qianKunStart from "../core/app-register"
    import {setState} from "../utils/app-store";
    import {ElLoading} from 'element-plus'

    export default {
        components: {},
        mounted() {
            if (sessionStorage.getItem('system')) {
                this.$store.dispatch('app/setSystem', sessionStorage.getItem('system'));
            }
        },
        computed: {
            // 导入vuex菜单数据，菜单折叠状态
            ...mapGetters(["is_collapse"]),
            ...mapGetters(["system"]),
            ...mapGetters(["navbread"]),

        },
        data() {
            return {
                fullscreenLoading: false,
                theme_menu: {
                    background: "#4671C4",
                    text: "#fff",
                    active_text: "#ffd04b"
                }, // 菜单主
                systemInfo: {
                    systemLogo: require('../static/img/home/logo.png'),
                },
                menu: [],
                showMenu: [],
                language: sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : 'ch',
                activeIndex: null,
                isSystem: "Link",
            };
        },
        beforeMount() {
            // let systemInfo = sessionStorage.getItem("systemInfo")
            // if (systemInfo) {
            //     systemInfo = JSON.parse(systemInfo)
            //     this.systemInfo = systemInfo
            // }
            this.getSystemInfo()
            this.getMenu();
        },
        methods: {
            getSystemInfo() {
                let formdata = new FormData()
                formdata.append("appId", this.$store.getters.userInfo.appId)
                apiPost(this.$basic + this.$getIndividuation, formdata).then((res) => {
                    if (res.data.code == "0000") {
                        if (res.data.attachObject) {
                            this.systemInfo = res.data.attachObject
                            if (!this.systemInfo.systemLogo)
                                this.systemInfo.systemLogo = require('../static/img/home/logo.png')
                        }
                    }
                })
            },
            getMenu() {
                let formdata = new FormData()
                let that = this
                formdata.append("appId", that.$store.getters.userInfo.appId)
                formdata.append("departmentId", that.$store.getters.userInfo.departmentId)
                if (that.$store.getters.userInfo && that.$store.getters.userInfo.iotSystem == 1) {
                    that.isSystem = "Cloud"
                } else if (that.$store.getters.userInfo && that.$store.getters.userInfo.id == 1) {
                    that.isSystem = ""
                }
                formdata.append("isSystem", that.isSystem)
                apiPost(this.$basic + this.$getMenu, formdata).then((res) => {
                    if (res.data.code == "0000") {
                        that.menu = []
                        res.data.attachObject.forEach(item => {
                            if (item.menus) {
                                for (let i in item.menus) {
                                    item.menus[i].activeRule = item.activeRule
                                    that.menu.push(item.menus[i])
                                }
                            }
                        })
                        that.menu = that.menu.sort(that.compare('sort'));
                        qianKunStart(res.data.attachObject);
                        setState({
                            msg: {menu: that.menu},
                        })
                        this.initMenu()
                    }
                })
            }
            ,
            initMenu() {
                if (this.menu && this.menu.length > 0) {
                    this.showMenu = []
                    this.menu.forEach(item => {
                        if ((this.system != "maintenance" && item.pluginId != 5) || (this.system == "maintenance" && item.pluginId == 5)) {
                            this.showMenu.push(item)
                        }
                    })
                    if (this.navbread && this.navbread.path && this.navbread.mid) {
                        let sub = {
                            guideChinese: this.navbread.parentChinese,
                            guideEnglish: this.navbread.parentEnglish,
                            entry: this.navbread.entry,
                        }
                        let item = {
                            path: this.navbread.path,
                            mid: this.navbread.mid,
                            guideChinese: this.navbread.childChinese,
                            guideEnglish: this.navbread.childEnglish,
                        }
                        this.addNavBread(sub, item)
                    } else {
                        this.addNavBread(this.showMenu[0], this.showMenu[0].children[0])
                        this.addBreadCrumb(this.showMenu[0], this.showMenu[0].children[0])
                    }
                }
            }
            ,
            compare(property) {
                return function (a, b) {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value1 - value2;
                }
            }
            ,
            goto(module, href) {
                const loading = ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.5)',

                })
                setTimeout(() => {
                    loading.close()
                }, 1000)
                routerGo(href, module);
                setState({
                    msg: {userInfo: this.$store.getters.userInfo},
                })
            }
            ,
            active(n) {
                this.$store.dispatch('menu/setActiveIndex', n)
                this.activeIndex = this.$store.state.activeIndex
            }
            ,
            addBreadCrumb(sub, item) {
                this.$store.dispatch('menu/addBreadCrumb', {sub, item});
            }
            ,
            addNavBread(sub, item) {
                this.$store.dispatch('menu/addNavBread', {sub, item})
            }
        },
        watch: {
            system: {
                handler(newVal, oldVal) {
                    this.initMenu()
                },
                immediate: true,
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .the-menu:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }

    .the-menu-logo {
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        /*background-color: #22292f !important;*/
        img {
            vertical-align: middle;
            height: 55px;
        }
    }

    .the-menu::-webkit-scrollbar {
        width: 0 !important;
    }

    .the-menu {
        height: calc(100% - 60px);
        background-color: #4671C4 !important;
        overflow-y: auto;
        overflow-x: hidden;
        border-right: none !important;

        /*border-color: #2a3f54;*/
        .el-menu-item {
            color: #ffffff;
            background-color: #3A62B3;
            min-width: 199px !important;
        }

        .el-menu-item.is-active {
            color: #ffd04b !important;
        }
    }
</style>
