// 配置js文件路径

// 开发
export default {
    authAccount: "hiwooiot",
    // BASEURL: 'http://121.37.175.76',
    // BASEURL: 'https://iot.witiot.com.cn',
    BASEURL: window.location.origin,

    environment_protection: "https://ep.hiwooiot.cn",
    energy: "https://es.hiwooiot.cn",
    factory: "https://pe.hiwooiot.cn",
    other: window.location.origin,

    maintenance: "/springboot/app-maintenance/",
    userMessage: {
        getUserMessage: "/message/getUserMessage",
        readUserMessage: "/message/readUserMessage",
    },

    // basic: ":8782/",
    basic: "/springboot/app-basic/",

    userSet: "user/register",
    editUser: "user/editUser",
    login: "user/loginCommoner",
    loginOut: "user/loginout",
    repass: "user/editPassword",
    resetPass: "user/resetPassword",

    getMenu: "jurisdiction/getMenuByUser",

    getIndividuation: "individuation/getIndividuation",

}
