<template>
    <div class="the-nav">
        <div class="the-breadcrumb">
            <!-- 菜单折叠按钮 -->
            <div class="nav-handle-collapse">
                <component :is="isCollapse?'Expand':'Fold'" :width="20" :height="20" style="margin: 14px 10px;"
                           @click="setMenuCollapseStatus()"/>
                <el-breadcrumb v-if="navbread" :separator-icon="ArrowRight" style="line-height: 50px">
                    <el-breadcrumb-item v-if="navbread.parentChinese">{{navbread.parentChinese}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: navbread.path }">{{navbread.childChinese}}</el-breadcrumb-item>
                </el-breadcrumb>
                <!--<div v-show="user.industry == 1  || user.industry == 2 || user.industry == 3"-->
                     <!--style="line-height: 50px;padding: 0px 20px;display: flex">-->
                    <!--<el-link :underline="false" target="_blank" @click="jump">-->
                        <!--<el-icon style="size: 16px">-->
                            <!--<medal/>-->
                        <!--</el-icon>-->
                        <!--演示平台-->
                    <!--</el-link>-->
                <!--</div>-->
            </div>
            <div v-if="user.id != 1" class="user">
                <el-tooltip
                        v-if="user.iomSystem != 1"
                        class="box-item"
                        effect="dark"
                        content="付费功能需额外开通"
                        placement="bottom"
                >
                    <el-link type="primary"
                             :disabled="user.iomSystem != 1"
                             :underline="false"
                             @click="switchSystem">
                        <el-icon size="14" color="#409eff">
                            <Sort/>
                        </el-icon>
                        {{currentSystem=="monitor"?"设备运维":"集中监控"}}
                    </el-link>
                </el-tooltip>
                <el-link v-else type="primary"
                         :underline="false"
                         @click="switchSystem">
                    <el-icon size="14" color="#409eff">
                        <Sort/>
                    </el-icon>
                    {{currentSystem =="monitor" ?"设备运维":"集中监控"}}
                </el-link>
                <el-affix :offset="8" @click="drawer = true" style="cursor:pointer;margin: 0px 32px">
                    <el-badge :value="messageCount" :max="99" class="item" type="warning">

                        <!--                      <el-dropdown-->
                        <!--                        trigger="click"-->
                        <!--                        :hide-on-click="false"-->
                        <!--                      >-->
                        <!--                      <span class="el-dropdown-link">-->
                        <el-icon size="25" color="#909399">
                            <Bell/>
                        </el-icon>
                        <!--                      </span>-->
                        <!--                        <template #dropdown>-->
                        <!--                          <el-dropdown-menu>-->
                        <!--                            <template v-if="messageCount <= 0">-->
                        <!--                              <el-dropdown-item>暂无消息</el-dropdown-item>-->
                        <!--                            </template>-->
                        <!--                            <template v-if="messageCount >= 0">-->
                        <!--                              <el-dropdown-item-->
                        <!--                                  :key="index"-->
                        <!--                                  v-for="(item, index) in userMessageList"-->
                        <!--                                  @click="readUserMessage(index)"-->
                        <!--                              > {{ item.message }} </el-dropdown-item>-->
                        <!--                            </template>-->
                        <!--                            <el-dropdown-item>Action 1</el-dropdown-item>-->
                        <!--                            <el-dropdown-item>Action 2</el-dropdown-item>-->
                        <!--                            <el-dropdown-item>Action 3</el-dropdown-item>-->
                        <!--                            <el-dropdown-item disabled>Action 4</el-dropdown-item>-->
                        <!--                            <el-dropdown-item divided>Action 5</el-dropdown-item>-->
                        <!--                          </el-dropdown-menu>-->
                        <!--                        </template>-->
                        <!--                      </el-dropdown>-->

                        <!--                        <el-icon size="20" color="#909399">-->
                        <!--                            <Bell/>-->
                        <!--                        </el-icon>-->
                    </el-badge>
                </el-affix>
                <el-dropdown @command="changeRouter">
                    <div class="el-dropdown-link">
                        <!--<el-avatar shape="square" :size="50" src="../static/img/home/head.png"></el-avatar>-->
                        <img class="headP" :src="headPortrait" alt="">
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="pass">修改密码</el-dropdown-item>
                            <el-dropdown-item command="info">个人信息</el-dropdown-item>
                            <el-dropdown-item command="exit">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div v-else class="user" style="justify-content:end">
                <el-dropdown @command="changeRouter">
                    <div class="el-dropdown-link">
                        <!--<el-avatar shape="square" :size="50" src="../static/img/home/head.png"></el-avatar>-->
                        <img class="headP" :src="headPortrait" alt="">
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="pass">修改密码</el-dropdown-item>
                            <el-dropdown-item command="info">个人信息</el-dropdown-item>
                            <el-dropdown-item command="exit">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <div class="the-tagcrumb">
            <router-link :class="navbread && navbread.mid == v.mid?'bread active-bread':'bread'" class="bread"
                         v-for="(v, i) in breadcrumb" :key="i" :to="v.path" @click="addNavbread(v)">
                {{v.childChinese}}
                <el-icon @click="delBreadCrumb(v)" style="vertical-align: middle;"
                         :color="navbread && navbread.mid == v.mid ?'#FFFFFF':'#409eff'">
                    <CloseBold/>
                </el-icon>
            </router-link>
        </div>
        <el-dialog v-model="visiblePassword" title="修改密码">
            <el-form :model="setPassword" :rules="rules" label-width="120px">
                <el-form-item label="原密码">
                    <el-input type="password" v-model="setPassword.orgPassword"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input type="password" v-model="setPassword.newPassword"></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input type="password" v-model="setPassword.confirmPassword"></el-input>
                </el-form-item>
                <div style="display: flex;justify-content: space-around">
                    <el-button @click="cancel">取消</el-button>
                    <el-button type="primary" @click="submit">确定</el-button>
                </div>
            </el-form>
        </el-dialog>
        <el-dialog v-model="visibleUser" title="个人信息">
            <el-form :model="user" label-width="120px">
                <el-form-item label="头像">
                    <el-upload
                            class="avatar-uploader"
                            action="#"
                            :show-file-list="false"
                            :http-request="httpRequest"
                            :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="user.headPortrait" :src="user.headPortrait" class="avatar"
                             style="height: 100px;width: 100px;"/>
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus/>
                        </el-icon>
                    </el-upload>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input :disabled="editor" v-model="user.userName"></el-input>
                </el-form-item>
                <el-form-item label="部门">
                    <el-input disabled v-model="user.departmentName"></el-input>
                </el-form-item>
                <el-form-item label="角色">
                    <el-input disabled v-model="user.roleName"></el-input>
                </el-form-item>
                <el-form-item label="手机号码">
                    <el-input :disabled="editor" v-model="user.phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input :disabled="editor" v-model="user.email"></el-input>
                </el-form-item>
                <div style="display: flex;justify-content: space-around">
                    <el-button @click="cancelUser">取消</el-button>
                    <el-button type="primary" @click="submitUser">确定</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>

    <el-drawer
            v-model="drawer"
            title="未读消息"
            direction="rtl"
    >
        <div style="margin-bottom: 10px">运维消息</div>

        <template v-if="messageCount <= 0">
            <div style="margin-top: 10px">暂无消息</div>
        </template>
        <template v-if="messageCount >= 0">
            <div
                    :key="index"
                    v-for="(item, index) in userMessageList"
                    @click="readUserMessage(index)"
                    style="margin-top: 10px; background-color: #F5F5F5; padding: 10px; border-radius: 5px; cursor:pointer"
                    :style="'background-color: ' + color"
                    @mouseover="enterDom($event)"
                    @mouseleave="leaveDom($event)"
            >
                <div>{{ item.message }}</div>
                <div style="margin-top: 20px; text-align: right">接收时间：<span style="color: #2a52b8; font-size: 16px"><b>{{ item.createTime }}</b></span>
                </div>
            </div>
        </template>
    </el-drawer>
</template>

<script>
    import {Expand, Fold, Bell, SetUp, CloseBold, View, Plus, Sort} from "@element-plus/icons";
    import {ElMessageBox, ElMessage} from 'element-plus'
    import {mapGetters} from "vuex";
    import {apiPost} from "@/utils/axios-utils";
    import {routerGo} from "@/utils/utils.js"; // 引入跨应用路由跳转
    import {noAuthApps, nextAuthApps} from "@/core/core-config";
    import qianKunStart from "@/core/app-register"
    import {ElLoading} from 'element-plus'
    import {setState} from "../utils/app-store";

    export default {
        name: "theNav",
        data() {
            return {
                user_name: "Admin", // 名称 // 用户信息
                full_screen: false, // 是否全屏
                setPassword: {
                    orgPassword: null,
                    newPassword: null,
                    confirmPassword: null
                },
                visiblePassword: false,
                rules: {},
                user: {},
                visibleUser: false,
                editor: false,
                currentnavbread: null,
                currentSystem: "monitor",  // maintenance
                userMessageList: [],
                messageCount: 0,
                drawer: false,
                color: '#F5F5F5',
                headPortrait: require('@/static/img/home/headPortrait.png')
            };
        },
        components: {
            Expand, Fold, Bell, SetUp, CloseBold, View, Plus, Sort
        },
        computed: {
            isCollapse() {
                return this.$store.getters.is_collapse;
            },
            ...mapGetters(["breadcrumb"]),
            ...mapGetters(["navbread"]),
            ...mapGetters({subappMsg: "msg"})
        },
        mounted() {
            let that = this
            if (that.$store.getters.system && that.$store.getters.system == "maintenance") that.currentSystem = "maintenance"
            if (sessionStorage.getItem('breadcrumb')) {
                let breadcrumbs = JSON.parse(sessionStorage.getItem("breadcrumb"))
                breadcrumbs.forEach(breadcrumb => {
                    let sub = {
                        guideChinese: breadcrumb.parentChinese,
                        guideEnglish: breadcrumb.parentEnglish,
                        entry: breadcrumb.entry,
                    }
                    let item = {
                        path: breadcrumb.path,
                        mid: breadcrumb.mid,
                        guideChinese: breadcrumb.childChinese,
                        guideEnglish: breadcrumb.childEnglish,
                    }
                    this.$store.dispatch('menu/addBreadCrumb', {sub, item});
                })
            }
            if (sessionStorage.getItem('activeIndex')) {
                that.$store.dispatch('menu/setActiveIndex', sessionStorage.getItem('activeIndex'));
            }
            if (sessionStorage.getItem('navbread')) {
                let navbread = JSON.parse(sessionStorage.getItem("navbread"))
                this.currentnavbread = navbread
                let sub = {
                    guideChinese: navbread.parentChinese,
                    guideEnglish: navbread.parentEnglish,
                    entry: navbread.entry,
                }
                let item = {
                    path: navbread.path,
                    mid: navbread.mid,
                    guideChinese: navbread.childChinese,
                    guideEnglish: navbread.childEnglish,
                }
                this.$store.dispatch('menu/addNavBread', {sub, item})
                this.goto(null, navbread.path)
            }
            this.user = this.$store.getters.userInfo
            if (this.user.headPortrait) this.headPortrait = this.user.headPortrait
            this.getUserMessage()
        },
        methods: {
            jump() {
                let url = ""
                switch (this.user.industry) {
                    case 1:
                        url = this.$environment_protection + "/cloud-login/?account=epuser&password=123456"
                        break;
                    case 2:
                        url = this.$energy + "/cloud-login/?account=esuser&password=123456"
                        break;
                    case 3:
                        url = this.$factory + "/cloud-login/?account=peuser&password=123456"
                        break;
                    case 4:
                        url = this.$other + "/cloud-login/?account=spcluser&password=123456"
                        break;
                }
                window.open(url);
            },
            switchSystem() {
                let that = this
                const loading = ElLoading.service({
                    lock: true,
                    text: '系统切换中...',
                    background: 'rgba(255, 255, 255, 0.5)',

                })
                that.currentSystem = that.currentSystem == "monitor" ? "maintenance" : "monitor"
                that.$store.dispatch('app/setSystem', that.currentSystem);
                let menu = sessionStorage.getItem("menu")
                let firstMenu = null
                setTimeout(() => {
                    that.$store.dispatch('menu/clearBreadCrumb');
                    if (menu) {
                        menu = JSON.parse(sessionStorage.getItem("menu"))
                        setState({
                            msg: {menu: menu},
                        })
                        if (that.currentSystem == "maintenance") {

                            for (let i in menu) {
                                if (menu[i].pluginId == 5) {
                                    firstMenu = menu[i]
                                    let sub = {
                                        guideChinese: firstMenu.guideChinese,
                                        guideEnglish: firstMenu.guideEnglish,
                                        entry: firstMenu.activeRule,
                                    }
                                    let item = {
                                        path: firstMenu.activeRule + firstMenu.children[0].path,
                                        mid: firstMenu.children[0].mid,
                                        guideChinese: firstMenu.children[0].guideChinese,
                                        guideEnglish: firstMenu.children[0].guideEnglish,
                                    }
                                    that.$store.dispatch('menu/addNavBread', {sub, item})
                                    that.$store.dispatch('menu/addBreadCrumb', {sub, item});
                                    that.goto(null, firstMenu.activeRule + firstMenu.children[0].path)
                                    break
                                }
                            }
                        } else {
                            for (let i in menu) {
                                if (menu[i].pluginId == 2) {
                                    firstMenu = menu[i]
                                    let sub = {
                                        guideChinese: firstMenu.guideChinese,
                                        guideEnglish: firstMenu.guideEnglish,
                                        entry: firstMenu.activeRule,
                                    }
                                    let item = {
                                        path: firstMenu.activeRule + firstMenu.children[0].path,
                                        mid: firstMenu.children[0].mid,
                                        guideChinese: firstMenu.children[0].guideChinese,
                                        guideEnglish: firstMenu.children[0].guideEnglish,
                                    }
                                    that.$store.dispatch('menu/addNavBread', {sub, item})
                                    that.$store.dispatch('menu/addBreadCrumb', {sub, item});
                                    that.goto(null, firstMenu.activeRule + firstMenu.children[0].path)
                                    break
                                }
                            }
                        }
                    }
                    loading.close()
                }, 1000)
            },
            goto(module, href) {
                const loading = ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.5)',

                })
                setTimeout(() => {
                    loading.close()
                }, 1000)
                routerGo(href, module);
            }
            ,
            // 设置左侧菜单折叠状态
            setMenuCollapseStatus() {
                this.$store.dispatch("menu/setCollapseStatus", !this.isCollapse);
            }
            ,
            changeRouter(i) {
                let that = this
                if (i === 'pass') {
                    that.setPassword = {
                        orgPassword: null,
                        newPassword: null,
                        confirmPassword: null
                    }
                    that.visiblePassword = true
                } else if (i === 'info') {
                    that.visibleUser = true
                } else if (i === 'exit') {
                    let formdata = new FormData()
                    formdata.append('account', that.user.account)
                    formdata.append('token', that.user.token)
                    formdata.append('client', "pc")
                    apiPost(that.$basic + that.$loginOut, formdata).then((res) => {
                        if (res.data.code == "0000") {
                            ElMessage({
                                message: res.data.message,
                                type: 'success',
                            })
                            qianKunStart(noAuthApps)
                            setTimeout(() => {
                                this.goto(null, "/")
                                window?.location?.reload?.();
                                sessionStorage.clear();
                                that.$store.dispatch('app/setUserInfo', null);
                                that.$store.dispatch('app/setToken', null);
                                that.$store.dispatch('app/setSystem', null);
                                that.$store.dispatch('menu/clearBreadCrumb', null);
                            }, 1000)

                        } else {
                            ElMessage({
                                message: res.data.message,
                                type: 'warning',
                            })
                        }
                    })
                }
            }
            ,
            delBreadCrumb(dreadCrumb) {
                this.$store.dispatch("menu/delBreadCrumb", dreadCrumb);
                let bread = this.$store.state.menu.breadcrumb[this.$store.state.menu.breadcrumb.length - 1]
                this.$store.dispatch('menu/addNavBread', {bread})
                this.currentnavbread = bread
                var path = bread.path
                if (path !== this.$route.path) this.$router.push(path)
                this.goto(null, path)
            }
            ,
            addNavbread(bread) {
                this.$store.dispatch('menu/addNavBread', {bread})
                this.currentnavbread = bread
                this.goto(null, bread.path)
            }
            ,
            submit() {
                const that = this
                ElMessageBox.confirm('确认修改密码？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let formdata = new FormData();
                    formdata.append("account", that.user.account)
                    formdata.append("orgPassword", that.setPassword.orgPassword)
                    formdata.append("newPassword", that.setPassword.newPassword)
                    formdata.append("confirmPassword", that.setPassword.confirmPassword)
                    apiPost(that.$basic + that.$repass, formdata).then((res) => {
                        if (res.data.code == "0000") {
                            sessionStorage.clear();
                            that.$store.dispatch('app/setUserInfo', null);
                            that.$store.dispatch('app/setToken', null);
                            ElMessage({
                                message: res.data.message,
                                type: 'success',
                            })
                            qianKunStart(noAuthApps)
                            setTimeout(() => {
                                this.goto(null, "/index")
                                window?.location?.reload?.();
                            }, 1000)
                        } else {
                            ElMessage({
                                message: res.data.message,
                                type: 'warning',
                            })
                        }
                    })
                }).catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消操作',
                    })
                })
            }
            ,
            cancel() {
                this.visiblePassword = false
            }
            ,
            httpRequest(data) {
                const that = this
                let rd = new FileReader() // 创建文件读取对象
                let file = data.file
                rd.readAsDataURL(file) // 文件读取装换为base64类型
                rd.onload = function (e) {
                    that.user.headPortrait = e.target.result // this指向当前方法onloadend的作用域
                }
                this.user.headPortraitFile = file
            }
            ,
            beforeAvatarUpload(file) {
                const isLt5M = file.size / 1024 / 1024 <= 5;
                if (!isLt5M) {
                    ElMessage({
                        message: '上传头像图片大小不能超过 5MB!',
                        type: 'error',
                    })
                }
                return isLt5M;
            }
            ,
            submitUser() {
                const that = this
                let formdata = new FormData();
                formdata.append("id", this.user.id)
                formdata.append("account", this.user.account)
                formdata.append("headPortraitFile", this.user.headPortraitFile)
                formdata.append("userName", this.user.userName)
                formdata.append("phone", this.user.phone)
                formdata.append("email", this.user.email)
                apiPost(this.$basic + this.$editUser, formdata).then((res) => {
                    if (res.data.code == "0000") {
                        sessionStorage.setItem("userInfo", JSON.stringify(that.user))
                        that.headPortrait = that.user.headPortrait
                        this.visibleUser = false;
                        ElMessage({
                            message: res.data.message,
                            type: 'success',
                        })
                    } else {
                        ElMessage({
                            message: res.data.message,
                            type: 'warning',
                        })
                    }
                })
            }
            ,
            cancelUser() {
                this.visibleUser = false;
            }
            ,
            getUserMessage() {
                let that = this
                let formData = new FormData
                formData.append('userId', that.user.id)
                formData.append('status', 0)

                apiPost(that.$maintenance + that.$userMessage.getUserMessage, formData).then((data) => {
                    if (data.data.code === '0000') {
                        that.userMessageList = data.data.attachObject
                        if (null === that.userMessageList || undefined === that.userMessageList)
                            that.messageCount = 0
                        else
                            that.messageCount = that.userMessageList.length
                    } else {
                        // this.$message({
                        //   type: 'error',
                        //   message: '获取数据失败，' + data.data.message
                        // });
                    }
                })
            },
            readUserMessage(index) {
                let that = this
                let formData = new FormData
                formData.append('id', that.userMessageList[index].id)
                formData.append('status', 1)

                apiPost(that.$maintenance + that.$userMessage.readUserMessage, formData).then((data) => {
                    if (data.data.code === '0000') {
                        that.userMessageList.splice(index, 1);
                        that.messageCount = that.userMessageList.length
                    } else {
                        // this.$message({
                        //   type: 'error',
                        //   message: '获取数据失败，' + data.data.message
                        // });
                    }
                })
            },
            enterDom(obj) {
                let dom = obj.currentTarget
                dom.style.backgroundColor = '#b1cef0'
            },
            leaveDom(obj) {
                let dom = obj.currentTarget
                dom.style.backgroundColor = '#F5F5F5'
            }
        }
    };
</script>

<style lang="scss" scoped>
    .the-nav {
        height: 95px;
        color: $header-color;
        box-shadow: #c1c0c0 1px 4px 5px 2px;
        box-sizing: border-box;
        .the-breadcrumb {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #CCCCCC;
            .nav-handle-collapse {
                display: flex;
                justify-content: space-between;
            }
            .user {
                line-height: 50px;
                width: 215px;
                display: flex;
                justify-content: space-between;
                vertical-align: middle;
                .el-dropdown-link {
                    cursor: pointer;
                    line-height: 10px;
                    span {
                        width: 30px;
                        display: inline-block;
                        box-sizing: border-box;
                        font-size: 14px;
                    }
                    img {
                        margin-right: 10px;
                    }
                }
                .el-icon-arrow-down {
                    font-size: 12px;
                    height: inherit;
                }
                .headP {
                    width: 50px;
                    height: 50px;
                    border-radius: 4px;
                    /*box-shadow: 0px 0px 5px 2px #93aabf;*/
                }
            }
        }
        .the-tagcrumb {
            display: flex;
            height: 35px;
            padding: 3px;
            .bread {
                border: 1px solid #409eff;
                /*border: 1px solid #00998a;*/
                margin: 0px 2px;
                height: 26px;
                line-height: 26px;
                border-radius: 4px;
                padding: 0px 7px;
                color: #409eff;
                /*color: #00998a;*/
                font-size: 12px;
                text-decoration: none;

                /*overflow: hidden;*/
                white-space: nowrap;
                /*text-overflow: ellipsis;*/
            }
            .active-bread {
                background: #409eff;
                /*background: #00998a;*/
                color: white;
            }
        }

    }
</style>
<style rel="stylesheet/scss" lang="scss">
    .el-drawer.rtl {
        overflow: scroll
    }

    .el-dropdown-menu {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        z-index: var(--el-dropdown-menu-index) !important;
        padding: 10px 0 !important;
        margin: 0 !important;
        background-color: #fff !important;
        border: none !important;
        border-radius: var(--el-border-radius-base) !important;
        box-shadow: none !important;
    }
</style>

