import chLocale from 'element-ui/lib/locale/lang/zh-CN'

const ch = {
    ...chLocale,
    welcome: '欢迎登录',
    setting: '设置',
    confirm: '确定',
    save: '保存',
    cancel: '取消',
    loading: '执行中',
    del: '删除',
    edit: '编辑',
    view: '查看',
    disable: '禁用',
    resetpass: '重置密码',
    yes: '是',
    no: '否',
    oldRepass: '旧密码',
    newRepass: '新密码',
    confirmRepass: '确认密码',
    personalInfo: '个人信息',
    data_Auth: '数据授权',
    rem_pass: '记住密码',
    slide_right: '请向右滑动',
    login: "登录",
    loginSuccess: "登录成功",
    loginFaild: "账号或密码错误",
    forget_pass: '忘记密码?',
    you_cancel_contr: "你已取消操作",
    sumbit: '提交',
    back: '返回',
    inp_name_tip: '只能为中文、数字、字母、@、_、-',
    inp_num_tip: '只能为1-100合法整数',
    inp_numMonth_tip: '当前保养周期只能为1-12合法整数',
    inp_numWeek_tip: '当前保养周期只能为1-4合法整数',
    inp_time_tip: '请输入时间',
    sel_time_tip: '请选择时间',
    inp_cycle_tip: '请输入周期',
    inp_accou_tip: '只能为数字、字母、@、_',
    inp_pass_tip: '只能为数字、字母、@、_',
    length_35: '长度不得超过35',
    new_old_diff: '密码不一致',
    user_err_tip: '含有除`_、@`之外的特殊字符',
    length_0_to_30: '长度必须在2-30之间',
    length_in: '长度不得超过',
    inp_phone_tip: '手机号格式不正确',
    inp_email_tip: '邮箱格式不正确',
    slide_verify: '请滑动验证',
    slide_success: '滑动成功',
    // 运营
    operating_cockpit: '运营驾驶舱',
    language: '语言切换',
    add_dash: '添加仪表板',
    full_screen: '全屏',
    del_dash: '删除仪表板',
    edit_dash: '编辑仪表板',
    quit_edit: '取消编辑',
    white: '经典白',
    blue: '工业蓝',
    equip_list: '设备列表',
    input: '请输入内容',
    search: '查询',
    equipid: '设备编号',
    detail: '详情',
    inp_dash_name: '请输入仪表板名称',
    inp_name: '请输入名称',
    tip: '提示',
    del_ind_continue: '是否确定移除仪表板中组件?',
    del_success: '删除成功',
    del_fail: '删除失败',
    copy_success: '复制成功',
    copy_fail: '复制失败',
    quit_del: '已取消删除',
    quit: '已取消',
    del_ins_continue: '此操作将永久删此除该仪表, 是否继续?',
    // 消息
    red_list: '报警消息列表',
    info_type: '消息类型',
    red_cont: '报警内容',
    belong_equip: '所属设备',
    info_status: '消息状态',
    red_time: '报警时间',
    control: '操作',
    readed: '标为已读',

    sel_all: '全选',
    recent_month: '最近一月',
    // 设备
    alarm_msg: '报警消息',
    alarm_time: '报警时间',
    handler: '处理人',
    handle_content: '处理内容',
    event_detail: '事件详情',
    no_equip_devide: "无分组设备",
    equip_drive: '设备驾驶舱',
    equip_name: '设备名称',
    data_source: '数据来源',
    addr: '地址',
    inputAddr: '输入地址',
    equip_base: '设备基本信息',
    equip_devide: '设备分组',
    create_time: '创建时间',
    firmNo: '厂商编号',
    creater: '创建人',
    equip_status: '设备状态',
    control_list: {
        now_data: '实时数据',
        active_red: '活跃报警',
        history_red: '历史报警',
        event_control: '事件监控',
        history_data: '历史数据',
        camera: '摄像头',
        yunzutai: '云组态'
    },
    dataid: '数据ID',
    collect_time: '采集时间',
    handle: '处理',
    red_deal: '报警处理',
    deal_describe: '处理描述',
    sub_success: '提交成功',
    deal_selected: '处理选中',
    dealed: '已处理',
    undeal: '未处理',
    sign_read: '标记已读',
    read: '已读',
    unread: '未读',
    control_data: '监控数据',
    data_name: '数据名称',
    data_num: '数据编号',
    belong_box: '所属盒子',
    readWrite_type: '读写类型',
    data_type: '数据类型',
    data_unit: '单位',
    red_data: '报警数据',
    rule_name: '规则名称',
    chufa: '触发条件',
    liandong: '联动动作',
    event_data: '事件数据',
    camera_name: '摄像头名称',
    camera_code: '摄像头编号',
    addequip: '添加设备',
    devidecontrol: '分组管理',
    equip_info: '设备信息',
    check_devide: '请选择分组',
    evt_cont: '事件内容',
    two_seven_length: '长度在 2 到 7 个字符',
    inp_num: '请输入编号',
    select_devide: '请选择分组',
    del_devide: '此操作将永久删除该分组, 是否继续?',
    del_equip_tip: '永久删除该设备,是否继续',
    del_camera_tip: '永久删除该摄像头, 是否继续?',
    equip_logo: '设备图标',
    data_peizhi: '数据配置',
    onlyRead: "只读",
    onlyWrite: "只写",
    ReadWrite: "读写",
    pro_name: '项目名称',
    import_box: '导入盒子',
    box_Id: '盒子ID',
    box_name: '盒子名称',
    set_box_data: '设置盒子',
    set_control_data: '设置监控数据',
    set_red_data: '设置报警数据',
    set_event_data: '设置事件数据',
    set_camera_data: '设置摄像头',
    sel_box: '选择盒子',
    set_camera: '设置摄像头',
    open_hignset: '高级配置',
    daixuan_box: '待选盒子列表',
    yixuan_box: '已选盒子列表',
    yixuan_red: '已选报警列表',
    daixuan_red: '待选报警列表',
    yixuan_event: '已选事件列表',
    daixuan_event: '待选事件列表',
    box_list: '盒子列表',
    daixuan_data: '待选数据列表',
    yixuan_data: '已选数据列表',
    red_rule: '报警规则',
    event: '事件',
    devide_name: '分组名称',
    add_devide: '添加子分组',
    related_equip: '添加设备',
    yichu: '移除',
    add_photo: '添加摄像头',
    bestNewTime: '最新更新时间',
    data_export: '数据导出',
    export_success: '导出成功',
    total_num: '总条数',
    tiao: '条数',
    yema: '页码',
    lat: '纬度',
    lng: '经度',
    now_no_data: '当前无数据',
    no_dataInfo: '暂无设备信息',
    // 用户中心
    creator: "创建者",
    power_grant: '权限授予',
    Report_Management: '报表管理',
    Config_management: '组态管理',
    Config_template: '组态模板',
    equip_power: '设备权限',
    red_power: '报警权限',
    event_power: '事件权限',
    camera_power: '视频权限',
    power_type: '权限类型',
    config_power: '组态权限',
    map_power: '地图权限',
    config_children: '配置子项',
    username: '用户名',
    account: '账号',
    role: '角色',
    roleType: '角色类型',
    pro_admin: '项目管理员',
    ordinary: '普通用户',
    status: '状态',
    add_user: '添加用户',
    add_role: "添加角色",
    edit_user: '编辑用户',
    password: '密码',
    email: '邮箱',
    phone: '手机',
    header: '用户头像',
    admin: '管理员',
    aver_user: '普通用户',
    sel_identity: '请选择身份',
    err_username: '用户名不符合规范',
    inp_account: '请输入账号',
    err_account: '账号格式不符合规范',
    inp_password: '请输入密码',
    inp_mail: '请输入邮箱',
    err_email: '邮箱不正确',
    inp_phone: '请输入手机号',
    err_phone: '手机格式不正确',
    upload_header: '请上传头像',
    click_upload: '点击上传',
    del_user: '此操作将永久删除该用户, 是否继续?',
    del_role: '此操作将永久删除该角色, 是否继续?',
    repass: '此操作将重置,初始密码为123456, 是否继续?',
    disableInfo: '此操作将禁用此用户, 是否继续',
    useableInfo: '此操作将启用此用户, 是否继续',
    repass_sucess: '重置成功',
    quit_repass: '取消重置',
    disable_sucess: '禁用成功',
    open_sucess: '启用成功',
    quit_disable: '取消禁用',
    role_name: '角色名称',
    role_edit: '角色编辑',
    role_add: '角色添加',
    role_desciption: '角色描述',
    contr_success: '操作成功',
    add_success: '添加成功',
    contr_fail: '操作失败',
    role_power: '角色权限',
    menu_power: '菜单权限',
    data_power: '数据权限',
    report_power: '告警接受权限',
    // camera_power: '摄像头查看权限',
    menu: '菜单',
    view_power: '查看权限',
    edit_power: '编辑权限',
    del_power: '删除权限',
    data: '数据',
    contr_type: '数据操作类型',
    read_power: '读权限',
    write_power: '写权限',
    red_info_type: '报警信息接收类型',
    please_sel: '请选择',
    // isAdmin: '是否注册为管理员',
    isAdmin: '是否注册为管理员',
    ple_sel_role: '请选择角色',
    func_permission: '功能权限',
    // 系统设置
    personalize: '个性化设置',
    user_logo: '公司logo',
    company_name: '公司名称',
    sys_name: '系统名称',
    info_sign: '短信签名',
    msg_total: '短信条数',
    msg_remain: '短信剩余',
    user_wechat_set: '微信公众号设置',
    user_ying_set: '萤石云账号设置',
    sel_event_type: '选择事件类型',
    start_time: '开始时间',
    end_time: '结束时间',
    num: '序号',
    event_type: '事件类型',
    controler: '操作人',
    contr_time: '操作时间',
    recent_day: '最近一天',
    recent_week: '最近一周',
    totalNum: '总条数',
    yiyong: '已用',
    weiyong: '未用',
    million: '百万',
    storage_time: '存储时长',
    is_cun: '是否存储',
    month: '月',
    inp_number: '输入数字',
    bg_file: '背景图',
    isUpdateProTotal: '是否修改该项目存储参数?',
    proMinNum: '项目最少条数不少于100万',
    isUpdateEditInfo: '是否更新修改信息?',
    no_change: "未作任何修改",
    allocated: '已分配',
    unallocated: '未分配',
    allocated_Num: '分配条数',
    data_deploy: '数据配置',
    IOT_management: '物联管理',
    dataControlData: '数据下发',
    //组态模板
    inp_scada_tip: '不能含有特殊字符！',
    inputNameReg: '请输入合法名称!',
    selectProject: '请选择项目',
    selectTemplate: '请选择模板',
    inputTemplateName: '请输入模板名称',
    scadaTemplateAdd: '创建模板',
    scadaTemplateDelete: '删除',
    scadaTemplateUpdate: '修改',
    scadaTemplateRun: '运行',
    scadaTemplateCopy: '复制',
    permissionChoose: '权限选择',
    //组态管理
    inputScadaName: '请输入组态名称',
    inputProjectName: '请选择项目',
    cadaName: '组态名称',
    scadaAdd: '创建组态',
    del_scada: '此操作将永久删除该组态, 是否继续?',
    copy_scada: '复制该组态, 是否继续?',
    edit_success: '修改成功',
    edit_fail: '修改失败',
    //设备监控
    ConfirmBatch: '确认是否批量处理？',
    handleSuccess: '处理成功!',
    handleFail: '处理失败!',
    //分析中心
    analyze: {
        add_btn: '添加报表',
        report_name: '报表名称',
        report_type: '报表类型',
        creator_name: '创建人',
        create_time: '创建时间'
    },
    // 项目中心
    project: {
        del_project_tip: '此操作将永远删除此项目，是否继续？',
        del_devide_tip: '此操作将永久删除此分组，是否继续？',
        del_project_success: '删除项目成功',
        del_devide_success: '删除分组成功'
    },

    // 维保中心
    export_excel: '导出Excel',
    belong_projcet: '所属项目',
    work_order_type: '工单类型',
    work_order_status: '工单状态',
    clear_condition: '清空条件',
    work_order_code: '工单编号',
    input_work_code: '请输入工单编号',
    device_name: '设备名称',
    create_user: '建单人',
    work_order_none: '暂无工单信息',
    work_order_view: '工单查看',
    work_order_info: '工单信息',
    work_order_flow: '工单流程',
    work_order_describe: '工单描述',
    contact_info: '联系方式',
    work_order_picture: '图片',
    no_allocated: '待分配',
    processed: '已处理',
    settled_orders: '已结单',
    create_work_order: '创建工单',
    edit_work_order: '编辑工单',
    update_work_order: '更新工单',
    please_sel_project: '请选择项目',
    input_device_name: '请选择真实设备',
    trouble_shooting: '故障维修',
    input_describe: '请描述说明工单！',
    camera_record: '照片记录',
    camera_tips: '至多上传5张照片，单张照片的大小控制在5M以内！',
    contacts: '联系人',
    contact_information: '联系方式',
    generate_work_order: '生成工单',
    work_order_processing: '工单处理',
    processing_records: '处理记录',
    input_process_content: '请详细记录处理内容！',
    back_off: '回退',
    processing: '处理',
    confirmation_processing: '确认处理',
    remarks: '备注',
    input_distribute: '请输入指派描述！',
    maintenance_personnel: '维保人员',
    input_user_name: '请选择真实用户',
    distribute_leaflets: '派单',
    reassignment: '改派',
    statement: '结单',
    evaluation_description: '评价描述',
    evaluation: '评价',
    input_evaluate: '请对此次工单处理做结单评价！',
    work_order_assignment: '工单指派',
    statement_processing: '结单处理',
    maintenance_type: '保养类型',
    plan_status: '计划状态',
    create_plan: '创建计划',
    planName: '计划名称',
    recent_maintenance: '最近维保时间',
    next_maintenance: '下次维保时间',
    read_plan: '查看计划',
    edit_plan: '编辑计划',
    plan_none: '暂无计划信息',
    maintenance_time: '保内保养时间',
    maintenance_cycle: '保养周期',
    advance_notice: '提前派单时间',
    generate_plan: '生成计划',
    update_plan: '更新计划',

    punchPerson: '打卡人员',
    contactInfo: '联系方式',
    punchAddress: '打卡地点',
    punchProject: '所属项目',
    punchDevice: '所属设备',
    punchTime: '打卡时间',
    punchCard: '生成二维码',

    //视频管理
    basicInfo: '基本配置',
    authBind: '认证绑定',
    addwebCam: '添加视频',
    input_webcam: '请输入视频名称或序列号',
    webcam: '摄像头',
    webcamName: '摄像头名称',
    serialNo: '序列号',
    ipAdd: 'IP/域名',
    port: '端口号',
    channelNo: '通道号',
    work_webcam_none: '暂无摄像头信息',
    sign: '标记',
    please_sel_camera: '请选择摄像头',
    addwebcam: '添加摄像头',
    viewWebcam: '详情查看',
    editWebcam: '编辑摄像头',
    authAcc: '认证账号',
    authPwd: '认证密码',
    input_auth_info: '请完善认证信息',
    input_code: '请输入合法序列号',
    input_ip: '请输入合法IP/域名',
    input_port: '请输入合法端口号',
    input_channel: '请输入合法通道',
    playerhd: '高清流',
    player: '标清流',
    update_info: '更新视频',
}
export default ch
