const getters = {
    system: state => state.app.system,
    token: state => state.app.token,
    userInfo: state => state.app.userInfo,
    is_collapse: state => state.menu.is_collapse,
    breadcrumb: state => state.menu.breadcrumb,
    navbread: state => state.menu.navbread,
    msg: state => state.appstore.msg
}

export default getters