import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
    ...enLocale,
    welcome: 'Welcome To Cloud',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    loading: 'Loading',
    edit: 'Edit',
    del: 'Delete',
    view: 'View',
    disable: 'Forbidden',
    resetpass: 'Reset Password',
    yes: 'YES',
    no: 'NO',
    personalInfo: 'Personal Info',
    data_Auth: 'Data Authorization',
    oldRepass: 'Old Password',
    newRepass: 'New Password',
    confirmRepass: 'Confirm Password',
    rem_pass: 'Remmember Password',
    slide_right: 'Please Slide Right',
    login: "Login",
    loginSuccess: "Login Successfully",
    loginFaild: "Wrong account or password",
    forget_pass: 'Forget Password?',
    you_cancel_contr: "You have cancelled the operation",
    sumbit: 'Submit',
    back: 'back',
    inp_name_tip: 'It can only be Chinese, numbers, letters, @_ 、-',
    inp_num_tip: 'It can only be numbers',
    inp_accou_tip: 'It can only be numbers, letters, _ 、-',
    length_35: 'The length shall not exceed 35',
    new_old_diff: 'Password inconsistency',
    slide_verify: 'Please slide to verify',
    slide_success: 'Slide Succeed',
    // 运营
    operating_cockpit: 'Operating Cockpit',
    language: 'Language',
    add_dash: 'Add Dashboard',
    full_screen: 'FullScreen',
    del_dash: 'DelDashboard',
    edit_dash: 'Dashboard Edit',
    quit_edit: 'Cancel',
    white: 'ClassicWhite',
    blue: 'IndustryBlue',
    equip_list: 'EquipList',
    input: 'Please Input',
    search: 'Search',
    equipid: 'EquipNum',
    detail: 'Detail',
    inp_dash_name: 'Please Input Dashboard Name',
    inp_name: 'Please Input Name',
    del_ind_continue: 'This operation will delete the indicator card. Do you want to continue?',
    del_success: 'Delete Success',
    del_fail: 'Delete Fail',
    quit_del: 'Deletion cancelled',
    copy_success: 'Copy Success',
    copy_fail: 'Copy Fail',
    del_ins_continue: 'This operation will permanently delete the instrument. Do you want to continue?',
    // 消息
    red_list: 'AlarmMessage',
    info_type: 'Type',
    red_cont: 'AlarmContent',
    belong_equip: 'Equipment',
    info_status: 'MessageStatus',
    red_time: 'AlarmTime',
    control: 'Handle',
    readed: 'Mark as Read',
    sel_all: 'Select all',
    recent_month: 'Last Month',
    // 设备
    no_equip_devide: "No packet device",
    equip_drive: 'Equipment Cockpit',
    equip_name: 'EquipName',
    data_source: 'Data Source',
    addr: 'Address',
    inputAddr: 'Enter address',
    equip_base: 'Basic Information of Equipment',
    equip_devide: 'Equipment Grouping',
    create_time: 'Creation Time',
    firmNo: 'Firm Code',
    creater: 'Creater',
    equip_status: 'Equipment status',
    control_list: {
        now_data: 'Real-Time Data',
        active_red: 'Active alarm',
        history_red: 'Historical alarm',
        event_control: 'Event monitoring',
        history_data: 'historical data',
        camera: 'Camera',
        yunzutai: 'Cloud Configuration'
    },
    dataid: 'Data ID',
    collect_time: 'Collect Time',
    handle: 'Handle',
    red_deal: 'Alarm Processing',
    deal_describe: 'Processing Description',
    sub_success: 'Submitted Successfully',
    deal_selected: 'Process Selected',
    del_camera_tip: 'Delete this Camera Forever, Do You Want to Continue?',
    dealed: 'Handled',
    undeal: 'UnHandled',
    sign_read: 'Mark Read',
    control_data: 'Monitor Data',
    data_name: 'Data Name',
    data_num: 'Data Number',
    belong_box: 'Box',
    readWrite_type: 'Read&Write Type',
    data_type: 'Data Type',
    data_unit: 'Data Unit',
    red_data: 'Alarm Data',
    rule_name: 'Rule Name',
    chufa: 'Trigger Condition',
    liandong: 'Linkage Action',
    event_data: 'Event Data',
    camera_name: 'Camera Name',
    camera_code: 'Camera Code',
    addequip: 'Add Device',
    evt_cont: 'Event Content',
    devidecontrol: 'Group Manage',
    equip_info: 'Device Information',
    check_devide: 'Please Select Group',
    two_seven_length: 'The length is between 2 and 7 characters',
    inp_num: 'Please Enter the Number',
    select_devide: 'Please Select Group',
    equip_logo: 'Device Icon',
    del_equip_tip: 'Permanently delete this device. Do you want to continue?',
    data_peizhi: 'Data Configuration',
    onlyRead: "Only Read",
    onlyWrite: "Only Write",
    ReadWrite: "Read & Write",
    import_box: 'Import Box',
    box_Id: 'Box ID',
    box_name: 'Box Name',
    set_box_data: 'Set Up Boxes',
    set_control_data: 'Set Up Monitoring Data',
    set_red_data: 'Set Alarm Data',
    set_event_data: 'Set Event Data',
    set_camera_data: 'Set Up the Camera',
    set_camera: 'Set Up the Camera',
    open_hignset: 'Advanced Configuration',
    daixuan_box: 'List of Boxes to be Selected',
    yixuan_box: 'Selected Box List',
    yixuan_red: 'List of Alarms to be Selected',
    daixuan_red: 'Selected Alarm List',
    box_list: 'Box List',
    daixuan_data: 'List of Data to be Selected',
    yixuan_data: 'Selected Data List',
    sel_box: 'Select Box',
    red_rule: 'Alarm Rules',
    del_devide: 'This Operation will Permanently Delete the List. Do You Want to Continue?',
    add_success: 'Add Success',
    event: 'Event',
    pro_name: 'Entry Name',
    devide_name: 'Group Name',
    add_devide: 'Add Subgroup',
    related_equip: 'Add Equipment',
    yichu: 'remove',
    bestNewTime: 'Latest update time',
    data_export: 'Export Data',
    export_success: 'Export success',
    tiao: 'PageSize',
    yema: 'PageNum',
    now_no_data: 'No Data NOW',
    lat: 'latitude',
    lng: 'longitude',
    no_dataInfo: 'No Equipment Information',
    // 用户中心
    power_grant: 'Permissions Grant',
    Report_Management: 'Report Management',
    Config_management: 'Configuration Management',
    Config_template: 'Configuration Template',
    equip_power: 'Device Permissions',
    red_power: 'Alarm Authority',
    event_power: 'Event Permissions',
    power_type: 'Permission Type',
    config_children: 'Configure Children',
    config_power: 'Configuration Permissions',
    map_power: 'Map Permissions',
    username: 'Username',
    account: 'Account',
    role: 'Role',
    roleType: 'Role Type',
    pro_admin: 'Project Manager',
    ordinary: 'Ordinary users',
    status: 'status',
    add_user: 'User Add',
    edit_user: 'Edit User',
    password: 'Password',
    email: 'Mail',
    phone: 'Phone',
    header: 'Head Portrait',
    admin: 'Administrator',
    aver_user: 'Average User',
    sel_identity: 'Please Select Identity',
    err_username: 'The userName does not conform to the specification',
    inp_account: 'Please Enter Your Account Number',
    err_account: 'The Account Format does not Conform to the Specification',
    inp_password: 'Please Enter Your Password',
    inp_mail: 'Please Enter Email Address',
    err_email: 'Incorrect Mailbox',
    err_phone: 'Incorrect Format of Mobile Phone',
    upload_header: 'Please Upload Your Picture',
    click_upload: 'Click Upload',
    del_user: 'This Operation will Permanently Delete the User. Do You Want to Continue?',
    del_role: 'This Operation will Permanently Delete the Role. Do You Want to Continue?',
    role_name: 'Role Name',
    disableInfo: 'This operation will disable this user. Do you want to continue',
    useableInfo: 'This operation will enable this user. Do you want to continue',
    role_edit: 'Role Editor',
    role_add: 'Role Addition',
    role_desciption: 'Role Description',
    contr_success: 'Handle Success',
    contr_fail: 'Handle Fail',
    role_power: 'Role Permissions',
    menu_power: 'Menu Permissions',
    data_power: 'Data Permission',
    report_power: 'Alarm Acceptance Authority',
    camera_power: 'Camera View Permission',
    menu: 'Menu',
    view_power: 'View Permission',
    del_power: 'Delete Permission',
    edit_power: 'Edit Peimission',
    data: 'Data',
    contr_type: 'Data Operation Type',
    read_power: 'Read Permission',
    write_power: 'Write Permission',
    red_info_type: 'Alarm Information Receive Type',
    please_sel: 'Please Select',
    isAdmin: 'Register as administrator',
    ple_sel_role: 'Please Select Role',
    func_permission: 'Function Permission',
    // 系统设置
    personalize: 'Personalization',
    user_logo: 'Corporate Logo',
    company_name: 'Corporate Name',
    sys_name: 'System Name',
    info_sign: 'SMS Signature',
    user_wechat_set: 'WeChat Official Account Settings',
    user_ying_set: 'YS7 account settings', // ezviz
    sel_event_type: 'Select Event Type',
    start_time: 'Start Time',
    end_time: 'End Time',
    num: 'Num',
    event_type: 'Event Type',
    contr_time: 'Handle Time',
    controler: 'Handler',
    recent_day: 'Recent Day',
    recent_week: 'Recent Week',
    totalNum: 'Total Number of Articles',
    yiyong: 'Used',
    weiyong: 'Not Used',
    million: 'Million',
    storage_time: 'Storage Time',
    is_cun: 'Stored?',
    bg_file: 'Background Image',
    isUpdateProTotal: 'Do you want to modify the project storage parameters？',
    proMinNum: 'The minimum number of projects shall not be less than 1 million',
    isUpdateEditInfo: 'Update modification information?',
    no_change: "No changes have been made",
    allocated: 'Allocated',
    unallocated: 'Unallocated',
    allocated_Num: 'Allocated Number',
    data_deploy: 'Data Configuration',
    IOT_management: 'IOT management',
    dataControlData: 'Control Data',
    //组态模板
    inputNameReg: 'Please enter a legal name!',
    selectProject: 'Please enter a project',
    inputTemplateName: 'Please enter a template name',
    scadaTemplateAdd: 'Create Template Name',
    scadaTemplateDelete: 'Delete',
    scadaTemplateUpdate: 'Update',
    scadaTemplateRun: 'Run',
    scadaTemplateCopy: 'Copy',
    //组态管理
    inputScadaName: 'Please enter a scada name',
    inputProjectName: 'Please enter a project name',
    cadaName: 'Sscada Name',
    scadaAdd: 'Create Scada',
    edit_success: 'Update Success',
    edit_fail: 'Update Fail',
    //设备监控
    ConfirmBatch: 'Confirm batch processing?',
    handleSuccess: 'Handle success!',
    handleFail: 'Handle fail!',
    //分析中心
    analyze: {
        add_btn: 'add Report',
        report_name: 'Report Name',
        report_type: 'Report Type',
        creator_name: 'Creator',
        create_time: 'Create Time'
    },
    // 项目中心
    project: {
        del_project_tip: 'This operation will delete this Item forever. Do you want to continue？',
        del_devide_tip: 'This operation will delete this Group forever. Do you want to continue？'
    },
    // 维保中心
    export_excel: 'Export excel',
    belong_projcet: 'Belonging to project',
    work_order_type: 'Work order type',
    work_order_status: 'Work order status',
    clear_condition: 'Clear condition',
    work_order_code: 'Work order code',
    input_work_code: 'Please enter the ticket number',
    device_name: 'Device name',
    create_user: 'Order creation user',
    work_order_none: 'No work order information',
    work_order_view: 'Work order view',
    work_order_info: 'Work order info',
    work_order_flow: 'Work order flow',
    work_order_describe: 'Work order describe',
    contact_info: 'Contact information',
    work_order_picture: 'Picture',
    no_allocated: 'To be allocated',
    processed: 'Processed',
    settled_orders: 'Settled orders',
    create_work_order: 'Create work order',
    edit_work_order: 'Edit work order',
    update_work_order: 'Update work order',
    please_sel_project: 'Please select item',
    input_device_name: 'Please enter device name',
    trouble_shooting: 'Troubleshooting',
    input_describe: 'Please describe the work order!',
    camera_record: 'Camera record',
    camera_tips: 'Upload up to 5 photos, and the size of a single photo is controlled within 5m!',
    contacts: 'Contacts',
    contact_information: 'Contact information',
    generate_work_order: 'Generate work order',
    work_order_processing: 'Work order processing',
    processing_records: 'Processing records',
    input_process_content: 'Please record the processing content in detail!',
    back_off: 'Back off',
    processing: 'Processing',
    confirmation_processing: 'Confirmation processing',
    remarks: 'Remarks',
    input_distribute: 'Please enter assignment description!',
    maintenance_personnel: 'Maintenance personnel',
    input_user_name: 'Please enter user name',
    distribute_leaflets: 'Distribute leaflets',
    reassignment: 'Reassignment',
    statement: 'Statement',
    evaluation_description: 'Evaluation Description',
    evaluation: 'Evaluation',
    input_evaluate: 'Please comment on the work order processing!',
    work_order_assignment: 'Work order assignment',
    statement_processing: 'Statement processing',
    maintenance_type: 'Maintenance type',
    plan_status: 'Plan status',
    create_plan: 'Create plan',
    recent_maintenance: 'The most recent time to maintain',
    next_maintenance: 'Next time',
    read_plan: 'View plan',
    edit_plan: 'Edit plan',
    plan_none: 'There is no planning information at this time',
    maintenance_time: 'In-house maintenance time',
    maintenance_cycle: 'Maintenance cycle',
    advance_notice: 'Send a work order in advance',
    generate_plan: 'Build the plan',
    update_plan: 'Update the plan',

    punchPerson: 'Clock in staff',
    contactInfo: 'contact information',
    punchAddress: 'Clock in location',
    punchProject: 'Project',
    punchDevice: 'Device',
    punchTime: 'Clock in time',
    punchCard: 'Generate QR code',


    //视频管理
    basicInfo: 'Basic configuration',
    authBind: 'Authentication binding',
    addwebCam: 'Add video',
    input_webcam: 'Please enter the video name or serial number',
    webcam: 'Camera',
    webcamName: 'Camera name',
    serialNo: 'Serial number',
    ipAdd: 'IP/Domain name',
    port: 'Port number',
    channelNo: 'Channel number',
    work_webcam_none: 'No camera information',
    sign: 'Sign',
    please_sel_camera: 'Please select camera',
    addwebcam: 'Add camera',
    viewWebcam: 'Details view',
    editWebcam: 'Edit camera',
    authAcc: 'Authentication account',
    authPwd: 'Authentication password',
    input_auth_info: 'Please improve the certification information',
    input_code: 'Please enter legal serial number',
    input_ip: 'Please enter legal IP / domain name',
    input_port: 'Please enter a valid port number',
    input_channel: 'Please enter legal channel',
    playerhd: 'High definition',
    player: 'Standard definition',
    update_info: 'Update video',

}
export default en
